import ExpDate from "./components/Experience/ExpDate";
import ExpDescription from "./components/Experience/ExpDescription";
import postit from "./images/post-it.png";
import showfinder from "./images/show-finder.png";
import sm from "./images/SM-Toolkit.jpg";
import gif from "./images/gif-gen.png";
import slack from "./images/slack-bot.png";
import eventify from "./images/eventify.png";
import doccam from "./images/DocCam.png";
import farmconnect from "./images/farmconnect.png";

export const experiences = [
	{
		date: (
			<ExpDate
				image={
					"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Toronto-Dominion_Bank_logo.svg/1200px-Toronto-Dominion_Bank_logo.svg.png"
				}
				height={80}
				width={90}
				company={"TD Bank"}
				time={"Present"}
			/>
		),
		description: (
			<ExpDescription
				title={"Software Engineer Intern"}
				description={
					"Maintaining a financial fraud prevention and management application using Angular, Java and Spring Boot."
				}
			/>
		),
	},
	{
		date: (
			<ExpDate
				image={
					"https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Toronto-Dominion_Bank_logo.svg/1200px-Toronto-Dominion_Bank_logo.svg.png"
				}
				height={80}
				width={90}
				company={"TD Bank"}
				time={"Jan-Apr 2023"}
			/>
		),
		description: (
			<ExpDescription
				title={"Software Engineer Intern"}
				description={
					"Enhanced internal processes by building a test management web application using React, Node JS, and developing automated tests using Java, Selenium and Tricentis Tosca."
				}
			/>
		),
	},

	{
		date: (
			<ExpDate
				image={
					"https://media.licdn.com/dms/image/C4E0BAQEc-nrLj6wVVw/company-logo_200_200/0/1657891988573?e=2147483647&v=beta&t=yXBklsh3ft9S_BdSQvmdKHRHTZW1MegDUYF1Yu542QA"
				}
				height={85}
				width={90}
				company={"Mappedin"}
				time={"Jan-Apr 2022"}
			/>
		),
		description: (
			<ExpDescription
				title={"Software Developer Intern"}
				description={
					"Developed client side features for a map editing tool by integrating React Typescript, MobX with deck.gl, a WebGL powered framework. Maintained legacy projects using React and Node JS."
				}
			/>
		),
	},
	{
		date: (
			<ExpDate
				image={"https://photos.prnewswire.com/prnfull/20160712/388648LOGO"}
				height={85}
				width={90}
				company={"Ruby"}
				time={"May-Aug 2021"}
			/>
		),
		description: (
			<ExpDescription
				title={"Software Developer Intern"}
				description={
					"Developed and maintained company's flagship web application using React, Redux, Typescript and Material UI."
				}
			/>
		),
	},
	{
		date: (
			<ExpDate
				image={"https://photos.prnewswire.com/prnfull/20160712/388648LOGO"}
				height={85}
				width={90}
				company={"Ruby"}
				time={"Sep-Dec 2020"}
			/>
		),
		description: (
			<ExpDescription
				title={"Software QA Engineer Intern"}
				description={
					"Developed 200+ API tests using Postman. Performed smoke and regression testing for releases. Maintained automated tests using Java, Selenium."
				}
			/>
		),
	},

	{
		date: (
			<ExpDate
				image={
					"https://media.licdn.com/dms/image/C4D0BAQH-3eccK4KY4Q/company-logo_200_200/0/1642119226360?e=2147483647&v=beta&t=EJKf9ATnps5gjkvXc5kDkmpsnv2UFXi_x8mx05RVm5Y"
				}
				height={85}
				width={95}
				company={"Vouchr"}
				time={"Jan-Apr 2020"}
			/>
		),
		description: (
			<ExpDescription
				title={"Software QA Analyst Intern"}
				description={
					"Planned, designed and executed manual tests for iOS, Android and Web versions of the product. Developed API tests on Postman using Javascript. Learned about SDLC and release procedures."
				}
			/>
		),
	},
	{
		date: null,
		description: null,
	},
];

export const projects = [
	{
		title: "Eventify",
		description: "A social web app for organizing and finding events.",
		stack: "React, Redux, GraphQL, MongoDB, Chakra UI",
		image: eventify,
		live: "https://eventify-client-three.vercel.app/",
		github: "https://github.com/mahdirahman1/eventify-client",
		type: "WEB",
	},
	{
		title: "Post It",
		description:
			"An online gallery for photographers with anonymous posting aided by auto image moderation",
		stack: "React, Redux, Firebase, Image Moderation API",
		image: postit,
		live: "https://post-it-gallery.netlify.app/",
		github: "https://github.com/mahdirahman1/post-it",
		type: "WEB",
	},
	{
		title: "FarmConnect",
		description:
			"Android app built for farmers with inventory management, voice recognition, marketplace and charity modes.",
		stack: "Android Studio, Kotlin, Jetpack Compose, Firebase",
		image: farmconnect,
		live: "https://www.youtube.com/watch?v=UIW_aBZMHVs",
		github: "https://github.com/mahdirahman1/FarmConnect",
		type: "MOBILE",
	},
	{
		title: "ShowFinder",
		description:
			"A front end web app for quickly finding your favorite tv shows. Backend in progress ",
		stack: "React, Redux, React Router, styled components",
		image: showfinder,
		live: "https://showfinderapp.netlify.app/",
		github: "https://github.com/mahdirahman1/show-app",
		type: "WEB",
	},
	{
		title: "Social Slack Bot",
		description:
			"A social bot to match slack users based on interests. Built at company hackathon at Mappedin",
		stack: "Javascript, Slack API",
		image: slack,
		live: null,
		github: "https://github.com/mahdirahman1/slack-social-bot",
		type: "OTHER",
	},
	{
		title: "Random GIF",
		description: "A random GIF generator app made using React custom hooks",
		stack: "React, Custom Hooks, GIPHY api",
		image: gif,
		live: "https://randomgifgenerator.netlify.app/",
		github: "https://github.com/mahdirahman1/random-gif",
		type: "WEB",
	},
	{
		title: "SM-Toolkit",
		description:
			"Web app to help students with basic math operations such as matrix manipulation, conversions and more.",
		stack: "Javascript, jQuery, HTML, CSS",
		image: sm,
		live: null,
		github: "https://github.com/mahdirahman1/SM-Toolkit",
		type: "WEB",
	},
	{
		title: "DocCam",
		description:
			"Android app that records and stores photos & videos of documents aiding students with managing school work",
		stack: "Android Studio, Java, Firebase",
		image: doccam,
		live: "https://play.google.com/store/apps/details?id=com.hucorp.android.doccam&pcampaignid=web_share",
		github: null,
		type: "MOBILE",
	},
];
