import React from "react";
import styled from "styled-components";
import { Timeline } from "antd";
import { experiences } from "../../info";

export const Wrapper = styled.div`
	text-align: center;
	color: #edf2f2;
	margin-top: 5rem;
	font-size: x-large;
`;
const TimelineWrapper = styled.div`
	width: 85%;
	margin-top: 5rem;

	@media (min-width: 600px) {
		width: 80%;
		margin-right: 20%;
	}
`;

const Divider = styled.hr`
	border: 1px solid #4bfaeb;
`;

const Experience = () => {
	return (
		<Wrapper>
			<h1>&lt; Experience /&gt;</h1>
			<TimelineWrapper>
				<Timeline
					mode="left"
					items={experiences.map((exp) => {
						return {
							label: exp.date,
							children: exp.description,
							color: "yellow",
						};
					})}
				/>
			</TimelineWrapper>
			<Divider />
		</Wrapper>
	);
};

export default Experience;
