import React from "react";

const ExpDescription = (props) => {
	const { title, description } = props;
	return (
		<>
			<h4 style={{ color: "#4bfaeb" }}>{title}</h4>
			<p style={{ fontSize: "1.2rem" }}>{description}</p>
		</>
	);
};

export default ExpDescription;
