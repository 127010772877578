import React from "react";
import { Col } from "antd";
import { Card as ProjCard } from "antd";
import { GithubOutlined, SelectOutlined } from "@ant-design/icons";

const Card = (props) => {
	const { title, description, stack, image, live, github } = props.project;
	const { Meta } = ProjCard;
	const getActions = () => {
		const result = [];
		if (github != null)
			result.push(
				<a href={github}>
					<GithubOutlined key="setting" style={{ fontSize: "150%" }} />
				</a>
			);
		if (live != null) {
			result.push(
				<a href={live}>
					<SelectOutlined key="edit" style={{ fontSize: "150%" }} />
				</a>
			);
		}
		return result;
	};
	return (
		<Col className="gutter-row" xs={24} md={12} xl={8}>
			<ProjCard
				style={{
					backgroundColor: "rgba(255, 255, 255, 0.0)",
					border: 0,
					height: "100%",
				}}
				headStyle={{
					backgroundColor: "rgba(255, 255, 255, 0.1)",
					borderBottom: "3px solid #4bfaeb",
					height: "3rem",
					maxHeight: "3rem",
				}}
				bodyStyle={{
					backgroundColor: "rgba(255, 255, 255, 0.1)",
					border: 0,
					height: "10rem",
					maxHeight: "10rem",
					paddingBottom: "1rem",
				}}
				bordered={false}
				cover={<img alt="project" src={image} height={"370rem"} />}
				actions={getActions()}
				extra={
					<p style={{ color: "#4bfaeb", fontSize: "1rem", textAlign: "left" }}>
						{stack}
					</p>
				}
			>
				<Meta
					style={{ textAlign: "left" }}
					title={
						<div style={{ lineHeight: 0.1 }}>
							<h1>{title}</h1>
						</div>
					}
					description={
						<div style={{ fontSize: "1rem" }}>
							<p>{description}</p>
						</div>
					}
				/>
			</ProjCard>
		</Col>
	);
};

export default Card;
