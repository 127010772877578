import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import TypeIt from "typeit-react";
import Typewriter from "typewriter-effect";
import { Fragment } from "react";

const HeaderWrapper = styled.div`
	padding: 9% 1rem;
	text-align: center;
	color: #edf2f2;
	position: relative;
	margin-bottom: 19rem;
`;

const Divider = styled.hr`
	border: 1px solid #4bfaeb;
`;

const Buttons = styled.div`
	position: absolute;
	top: 30rem;
	left: 0;
	right: 0;
	margin-top: 2rem;
`;

const StyledButton = styled.button`
	background: transparent;
	border: 2px solid #4bfaeb;
	border-radius: 1.5rem;
	font-size: 1.5rem;
	padding: 0.5rem;
	color: white;
	margin: 0 0.5rem;
	height: 3.5rem;
	width: 3.5rem;
	opacity: 0.8;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	@media (max-width: 320px) {
		margin: 0 0.2rem;
	}

	&:hover {
		color: #4bfaeb;
		opacity: 1;
		transform: scale(1.1);
		border: 3px solid #4bfaeb;
	}
`;

const Header = () => {
	return (
		<Fragment>
			<HeaderWrapper>
				<motion.div
					initial={{ y: -200, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.75, type: "spring" }}
				>
					<div className="wave">👋</div>
					<TypeIt className="intro" options={{ waitUntilVisible: true }}>
						Hi, <span className="name">Mahdi</span> here.
					</TypeIt>
					<div style={{ maxHeight: "2rem" }}>
						<Typewriter
							options={{
								strings: [
									"Full Stack Developer",
									"Front End React Developer",
									"Android Developer",
									"Problem Solver",
									"Computer Engineering Student @UWaterloo",
									"Loves working with the Front End",
									"Is exploring Backend technologies",
									"Mainly uses MongoDB, Express, React, Node JS (MERN)",
									"Currently picking up Angular and Java Spring Boot",
									"Values Software Testing & QA",
									"Likes photography, gaming and cricket",
									"Would like to connect with you",
									"Is thinking what to write next...",
								],
								autoStart: true,
								loop: true,
								deleteSpeed: 30,
								delay: 50,
							}}
						/>
					</div>
				</motion.div>

				<Buttons>
					<motion.div
						initial={{ x: 50, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ delay: 1.5, type: "tween" }}
					>
						<a href="https://github.com/mahdirahman1">
							<StyledButton>
								<i class="fab fa-github"></i>
							</StyledButton>
						</a>
						<a href="mailto:mahdi.rahman@uwaterloo.ca">
							<StyledButton>
								<i class="fas fa-envelope"></i>
							</StyledButton>
						</a>
						<a href="https://drive.google.com/file/d/1BAOaVdzHc_h6mpg4I-QnKu3g09q81zpA/view?usp=sharing">
							<StyledButton>
								<i class="fas fa-file-pdf"></i>
							</StyledButton>
						</a>
						<a href="https://ca.linkedin.com/in/mahdi-rahman-4a3569155">
							<StyledButton>
								<i class="fab fa-linkedin"></i>
							</StyledButton>
						</a>
					</motion.div>
				</Buttons>
			</HeaderWrapper>
			<Divider />
		</Fragment>
	);
};

export default Header;
