import React, { useState } from "react";
import { Wrapper } from "../Experience/Experience";
import { Row } from "antd";
import { projects } from "../../info";
import Card from "./Card";
import { Button } from "antd";
import styled from "styled-components";

const ButtonGroup = styled.div`
	margin-bottom: 2rem;
`;

const FilterButton = styled(Button)`
	margin: 0.2rem;
	width: 5rem;
`;

const Projects = () => {
	const [selected, setSelected] = useState("ALL");
	const [displayed, setDisplayed] = useState(projects);
	const getProps = (filter) => {
		return { type: "default", ghost: selected !== filter };
	};
	const filterHandler = (e) => {
		const selected = e.target.textContent;
		setSelected(selected);
		if (selected === "ALL") {
			setDisplayed(projects);
		} else {
			setDisplayed(
				projects.filter((proj) => proj.type === e.target.textContent)
			);
		}
	};
	return (
		<Wrapper style={{ marginBottom: "5rem" }}>
			<h1 style={{ marginBottom: "3rem" }}>&lt; Projects /&gt;</h1>
			<ButtonGroup>
				<FilterButton size="large" onClick={filterHandler} {...getProps("ALL")}>
					ALL
				</FilterButton>
				<FilterButton
					ghost
					size="large"
					onClick={filterHandler}
					{...getProps("WEB")}
				>
					WEB
				</FilterButton>
				<FilterButton
					ghost
					size="large"
					onClick={filterHandler}
					{...getProps("MOBILE")}
				>
					MOBILE
				</FilterButton>
				<FilterButton
					ghost
					size="large"
					onClick={filterHandler}
					{...getProps("OTHER")}
				>
					OTHER
				</FilterButton>
			</ButtonGroup>
			<Row gutter={[36, 36]}>
				{displayed.map((proj) => (
					<Card project={proj} key={proj.title} />
				))}
			</Row>
		</Wrapper>
	);
};

export default Projects;
