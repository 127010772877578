import "./App.css";
import Experience from "./components/Experience/Experience";
import Header from "./components/Header";
import { ConfigProvider } from "antd";
import Projects from "./components/Projects/Projects";

function App() {
	return (
		<div className="App">
			<ConfigProvider
				theme={{
					components: {
						Timeline: {
							/* here is your component tokens */
							tailColor: "#4bfaeb",
							tailWidth: 7,
							dotBorderWidth: 8.5,
							fontSize: 25,
							margin: 40,
							padding: 65,
							colorText: "white",
							lineHeight: 1,
							fontFamily: "Glory",
							lineType: "dotted",
						},
						Card: {
							actionsBg: "rgba(255, 255, 255, 0.1)",
						},
						Button: {
							/* here is your component tokens */
							defaultBg: "#1cbdaf",
							textHoverBg: "#1cbdaf",
						},
					},
					token: {
						colorText: "white",
						colorTextDescription: "white",
						colorBorderSecondary: "#4bfaeb",
						fontFamily: "Glory",
						colorBgTextHover: "#1cbdaf",
					},
				}}
			>
				<Header />
				<Experience />
				<Projects />
			</ConfigProvider>
		</div>
	);
}

export default App;
