import React from "react";

const ExpDate = (props) => {
	const { image, height, width, company, time } = props;
	return (
		<div
			style={{
				marginRight: "2rem",
				fontSize: "1.3rem",
				lineHeight: 0.6,
				whiteSpace: "nowrap",
				textAlign: "right",
			}}
		>
			<img src={image} width={width} height={height} alt="logo"></img>
			<p>{company}</p>
			<p>{time}</p>
		</div>
	);
};

export default ExpDate;
